import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgShoppingCart03 = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5 14h13.136c1.013 0 1.52 0 1.922-.189a2 2 0 0 0 .858-.768c.232-.38.288-.883.4-1.89l.585-5.265c.035-.307.052-.46.002-.58a.5.5 0 0 0-.22-.246C21.57 5 21.416 5 21.106 5H4.5M2 2h1.248c.265 0 .397 0 .5.05a.5.5 0 0 1 .217.204c.057.1.065.232.082.496l.906 14.5c.017.264.025.396.082.496a.5.5 0 0 0 .216.204c.104.05.236.05.5.05H19M7.5 21.5h.01m8.99 0h.01M8 21.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm9 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
    />
  </svg>
);
export default SvgShoppingCart03;
