import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgDragIndicator = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }}
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.119 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm7.762 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM8.119 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm7.762 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM8.119 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm7.762 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      stroke="currentColor"
      strokeWidth={2.5}
    />
  </svg>
);
export default SvgDragIndicator;
