import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMarkerPinSolid = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }}
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 21.997c4-4 8-7.582 8-12a8 8 0 0 0-16 0c0 4.418 4 8 8 12Zm0-7.801a4.094 4.094 0 1 0 .001-8.189A4.094 4.094 0 0 0 12 14.196Z"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={2.5}
    />
  </svg>
);
export default SvgMarkerPinSolid;
