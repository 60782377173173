import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHeading7 = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 83 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M.432 5.794V1.818H21.32v3.976h-8.092V28H8.524V5.794H.432ZM24.424 28V8.364h4.627V28h-4.627ZM26.75 5.577a2.7 2.7 0 0 1-1.892-.729c-.528-.494-.793-1.087-.793-1.777 0-.699.265-1.291.793-1.777A2.674 2.674 0 0 1 26.75.553c.742 0 1.372.247 1.892.741.529.486.793 1.078.793 1.777 0 .69-.264 1.283-.793 1.777-.52.486-1.15.729-1.892.729ZM33.09 28V8.364h4.423V11.7h.23c.41-1.125 1.087-2.003 2.033-2.633.946-.64 2.075-.959 3.388-.959 1.33 0 2.45.324 3.362.972.92.639 1.568 1.512 1.943 2.62h.205c.435-1.09 1.168-1.96 2.199-2.608 1.04-.656 2.271-.984 3.695-.984 1.806 0 3.28.571 4.423 1.713 1.142 1.142 1.713 2.808 1.713 4.999V28h-4.64V15.536c0-1.22-.325-2.11-.972-2.672-.648-.571-1.44-.857-2.378-.857-1.117 0-1.99.35-2.621 1.048-.622.69-.933 1.59-.933 2.698V28H44.62V15.344c0-1.014-.306-1.824-.92-2.43-.605-.604-1.398-.907-2.378-.907-.665 0-1.27.17-1.815.511-.546.333-.98.806-1.304 1.42-.324.605-.486 1.312-.486 2.122V28H33.09Zm40.282.384c-1.969 0-3.67-.41-5.1-1.228-1.424-.826-2.52-1.994-3.286-3.503-.767-1.517-1.151-3.302-1.151-5.356 0-2.02.384-3.793 1.15-5.318.776-1.534 1.859-2.728 3.248-3.58 1.389-.86 3.021-1.291 4.896-1.291 1.21 0 2.352.196 3.426.588a7.743 7.743 0 0 1 2.864 1.79c.835.81 1.491 1.84 1.969 3.093.477 1.245.715 2.728.715 4.45v1.418H66.008v-3.119h11.66c-.01-.886-.2-1.675-.576-2.365a4.183 4.183 0 0 0-1.572-1.65c-.665-.4-1.44-.6-2.327-.6-.946 0-1.777.23-2.493.69a4.756 4.756 0 0 0-1.675 1.79 5.15 5.15 0 0 0-.6 2.416v2.723c0 1.142.208 2.122.626 2.94.418.81 1.001 1.433 1.751 1.867.75.426 1.628.64 2.634.64.673 0 1.283-.094 1.828-.282a4.057 4.057 0 0 0 1.419-.856c.4-.375.703-.84.908-1.394l4.32.486a6.736 6.736 0 0 1-1.559 2.991c-.758.844-1.73 1.5-2.915 1.97-1.184.46-2.54.69-4.065.69Z"
      fill="currentColor"
      strokeWidth={2.5}
    />
  </svg>
);
export default SvgHeading7;
